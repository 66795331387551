import { graphql, useStaticQuery } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Container from 'components/Container'
import DateTime from 'components/DateTime'
import Head from 'components/Head'
import Heading from 'components/Heading'
import Layout from 'components/Layout'
import Link from 'components/Link'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import useEventPageVisited from 'hooks/use-event-page-visited'

export default function Press() {
  useEventPageVisited('Press')
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { frontmatter: { template: { eq: "press" } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              date
              url
              source
            }
          }
        }
      }
    }
  `)

  return (
    <Layout className="Press">
      <Head
        title="Press"
        description="The latest news and updates from Bold, your home for personalized, science-backed workouts shown to reduce risk of falls."
      />
      <View>
        <Container flush size="xlarge">
          <Heading align="center" level={2} levelStyle={2}>
            Press
          </Heading>
          <Container align="left" flush size="large">
            {data.allMdx.edges.map(({ node }, index) => {
              const { fields, frontmatter, id } = node
              const { slug } = fields
              const { title, date, url, source } = frontmatter

              return (
                <Row flush={index === _.size(data.allMdx.edges)} key={id} size="large">
                  <Link to={url || slug} color="grey">
                    <div>
                      <Row size="xxsmall">
                        <DateTime color="default" data={date} weight="bold" />
                      </Row>
                      <Heading flush={!source} level={3}>
                        {title}
                      </Heading>
                      {source && (
                        <Text casing="upper" color="default" element="small" weight="bold">
                          {source}
                        </Text>
                      )}
                    </div>
                  </Link>
                </Row>
              )
            })}
          </Container>
        </Container>
      </View>
    </Layout>
  )
}
